.whatwedo {
	padding: 115px 0;
	@include md {
		padding: 100px 0 50px; }
	&__innertop {}
	&__inner {
		align-items: center;
		display: flex;
		@include sm {
			align-items: flex-start;
			flex-direction: column; } }
	&__titleinfo {
		margin-bottom: 12px; }
	&__title {
		margin-bottom: 20px;
		@include md {
			margin-bottom: 20px; } }
	&__items {
		margin: 0 -33px; }
	&__col {
		padding: 0 33px;
		display: flex;
		flex-direction: column;
		justify-content: space-between; }
	&__img {
		margin-bottom: 34px;

		align-items: center;
		display: flex;

		img {
			max-width: 100%;
			top: 50%;
			left: 50%;
			height: 150px;
			width: 150px;
			padding 10px {}
			@include md {
				height: 80px;
				width: 80px; } } }


	&__title-sm {
		margin-bottom: 26px;
		@include f(20px,900,1.35);
		color: $black; }
	&__footer {
		margin-top: 28px; }
	&__sliderimg {
		padding-top: 30px;
		padding-left: 10px;
		width: 40%;
		@include sm {
			margin-bottom: 30px;
			width: 100%;
			max-width: 320px;
			display: none; } }
	&__sliderimgmobile {
		padding-top: 30px;
		padding-left: 10px;
		width: 40%;
		display: none;
		@include sm {
			margin-bottom: 30px;
			width: 100%;
			max-width: 320px;
			display: block; } }
	&__slidermiddle {
		width: 10%;
		min-width: 10px;
		@include sm {
			display: none; } }
	&__slidernav {
		width: 60%;
		@include sm {
			width: 100%; } }
	&__topic {
		margin-bottom: 35px;
		justify-content: space-between;
		display: flex;
		flex-wrap: wrap;
		letter-spacing: 0.1em;
		@include md {
			margin-bottom: 20px; } }
	&__name {
		color: rgba($black, .5);
		font-size: 14px;
		line-height: 1.35;
		text-transform: uppercase; }
	&__counter {
		font-size: 14px;
		color: rgba($black, .3); } }
