/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

a { text-decoration: none; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) { * { transition: none !important; } }

@font-face { font-family: "Avenir"; font-style: normal; font-weight: 400; src: local("Avenir"), local("Avenir-Roman"), url("../fonts/Avenir-Roman.woff2") format("woff2"), url("../fonts/Avenir-Roman.woff") format("woff"); }

@font-face { font-family: "Avenir"; font-style: normal; font-weight: 500; src: local("Avenir Medium"), local("Avenir-Medium"), url("../fonts/Avenir-Medium.woff2") format("woff2"), url("../fonts/Avenir-Medium.woff") format("woff"); }

@font-face { font-family: "Avenir"; font-style: normal; font-weight: 800; src: local("Avenir Heavy"), local("Avenir-Heavy"), url("../fonts/Avenir-Heavy.woff2") format("woff2"), url("../fonts/Avenir-Heavy.woff") format("woff"); }

@font-face { font-family: "Avenir"; font-style: normal; font-weight: 900; src: local("Avenir Black"), local("Avenir-Black"), url("../fonts/Avenir-Black.woff2") format("woff2"), url("../fonts/Avenir-Black.woff") format("woff"); }

.icon-arr { width: 1.57em; height: 1em; fill: currentColor; }

.icon-github { width: 1em; height: 1em; fill: currentColor; }

.icon-linkedin { width: 0.9em; height: 1em; fill: currentColor; }

.icon-twitter { width: 1em; height: 1em; fill: currentColor; }

@media only screen and (max-width: 1023px) { html.is-overflow-hidden { overflow-x: hidden; overflow-y: scroll; } }

body { color: rgba(0, 0, 0, 0.4); min-width: 320px; font-size: 18px; font-weight: 400; line-height: 1.9; font-family: Avenir, Arial, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; background-color: #fff; }

@media only screen and (max-width: 1023px) { body { font-size: 16px; line-height: 1.5; } }

@media only screen and (max-width: 1023px) { .is-overflow-hidden body { overflow: hidden; height: 100vh; } }

.title-lg, .title-md, .title, .title-sm, .title-xs { font-family: Avenir, Arial, sans-serif; font-weight: 900; color: #000; }

.title-lg { position: relative; font-size: 70px; line-height: 1.1; }

@media only screen and (max-width: 1259px) { .title-lg { font-size: 65px; } }

@media only screen and (max-width: 1023px) { .title-lg { font-size: 50px; } }

@media only screen and (max-width: 767px) { .title-lg { font-size: 34px; } }

.title-lg span { display: block; }

.title-lg.has-cube, .title-md.has-cube { padding-top: 43px; }

@media only screen and (max-width: 1023px) { .title-lg.has-cube, .title-md.has-cube { padding-top: 0; } }

.title-lg.has-cube:before, .title-md.has-cube:before { position: absolute; content: ''; top: 0; left: 0; height: 11px; width: 11px; background-color: #000; }

@media only screen and (max-width: 1023px) { .title-lg.has-cube:before, .title-md.has-cube:before { display: none; } }

.title-md { font-size: 60px; line-height: 1.36; }

@media only screen and (max-width: 1023px) { .title-md { font-size: 40px; } }

.title { position: relative; font-size: 40px; line-height: 1.25; }

@media only screen and (max-width: 1023px) { .title { font-size: 30px; } }

.title.has-cube-dot:after { content: ''; margin-left: 10px; height: 10px; width: 10px; background-color: #002DDC; display: inline-block; }

.title-sm { font-size: 30px; line-height: 1.32; }

.title-xs { font-size: 24px; line-height: 1.375; }

.titleinfo { position: relative; z-index: 1; font-weight: 500; font-size: 14px; line-height: 19px; font-size: 14px; font-weight: 500; line-height: 1.4; text-align: center; text-transform: uppercase; letter-spacing: 0.1em; color: rgba(0, 0, 0, 0.5); vertical-align: top; display: inline-block; }

.titleinfo_mod:before, .titleinfo_mod:after { display: none; }

.titleinfo:before, .titleinfo:after { position: absolute; content: ''; top: 50%; left: 50%; z-index: -1; border-radius: 50%; border: 1px solid rgba(0, 0, 0, 0.05); transform: translate(-50%, -50%); }

.titleinfo:before { height: 170px; width: 170px; }

.titleinfo:after { height: 64px; width: 64px; }

.blue { color: #002DDC; }

.text-center { text-align: center; }

.btn, .btn-simle, .more { font-size: 14px; font-weight: 800; line-height: 1.36; letter-spacing: 0.1em; text-transform: uppercase; font-family: Avenir, Arial, sans-serif; -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, opacity 0.3s ease-in-out; transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, opacity 0.3s ease-in-out; vertical-align: top; display: inline-block; }

.btn .icon-arr, .btn-simle .icon-arr, .more .icon-arr { margin-left: 15px; margin-bottom: 1px; font-size: 7px; line-height: 1; transform: rotate(-90deg); }

.btn { padding: 10px 20px 9px 24px; color: #002DDC; border: 1px solid #002DDC; }

.no-touch .btn:hover { color: #fff; background-color: #002DDC; }

.btn-simle { padding: 10px 0 9px; color: #000; }

.no-touch .btn-simle:hover { color: rgba(0, 0, 0, 0.6); }

.more { color: #002DDC; }

.no-touch .more:hover { opacity: .8; }

.more .icon-arr { margin-left: 8px; }

.icon2 { display: inherit !important; }

.label { text-transform: uppercase; letter-spacing: 0.1em; color: rgba(0, 0, 0, 0.3); font-size: 14px; font-weight: 500; line-height: 1.356; }

.row { margin: 0px -15px; display: flex; flex-wrap: wrap; }

.col { padding: 0 15px; }

.col-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }

@media only screen and (max-width: 1023px) { .col-md-1 { flex: 0 0 8.33333%; max-width: 8.33333%; } }

@media only screen and (max-width: 767px) { .col-sm-1 { flex: 0 0 8.33333%; max-width: 8.33333%; } }

.col-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }

@media only screen and (max-width: 1023px) { .col-md-2 { flex: 0 0 16.66667%; max-width: 16.66667%; } }

@media only screen and (max-width: 767px) { .col-sm-2 { flex: 0 0 16.66667%; max-width: 16.66667%; } }

.col-3 { flex: 0 0 25%; max-width: 25%; }

@media only screen and (max-width: 1023px) { .col-md-3 { flex: 0 0 25%; max-width: 25%; } }

@media only screen and (max-width: 767px) { .col-sm-3 { flex: 0 0 25%; max-width: 25%; } }

.col-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }

@media only screen and (max-width: 1023px) { .col-md-4 { flex: 0 0 33.33333%; max-width: 33.33333%; } }

@media only screen and (max-width: 767px) { .col-sm-4 { flex: 0 0 33.33333%; max-width: 33.33333%; } }

.col-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }

@media only screen and (max-width: 1023px) { .col-md-5 { flex: 0 0 41.66667%; max-width: 41.66667%; } }

@media only screen and (max-width: 767px) { .col-sm-5 { flex: 0 0 41.66667%; max-width: 41.66667%; } }

.col-6 { flex: 0 0 50%; max-width: 50%; }

@media only screen and (max-width: 1023px) { .col-md-6 { flex: 0 0 50%; max-width: 50%; } }

@media only screen and (max-width: 767px) { .col-sm-6 { flex: 0 0 50%; max-width: 50%; } }

.col-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }

@media only screen and (max-width: 1023px) { .col-md-7 { flex: 0 0 58.33333%; max-width: 58.33333%; } }

@media only screen and (max-width: 767px) { .col-sm-7 { flex: 0 0 58.33333%; max-width: 58.33333%; } }

.col-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }

@media only screen and (max-width: 1023px) { .col-md-8 { flex: 0 0 66.66667%; max-width: 66.66667%; } }

@media only screen and (max-width: 767px) { .col-sm-8 { flex: 0 0 66.66667%; max-width: 66.66667%; } }

.col-9 { flex: 0 0 75%; max-width: 75%; }

@media only screen and (max-width: 1023px) { .col-md-9 { flex: 0 0 75%; max-width: 75%; } }

@media only screen and (max-width: 767px) { .col-sm-9 { flex: 0 0 75%; max-width: 75%; } }

.col-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }

@media only screen and (max-width: 1023px) { .col-md-10 { flex: 0 0 83.33333%; max-width: 83.33333%; } }

@media only screen and (max-width: 767px) { .col-sm-10 { flex: 0 0 83.33333%; max-width: 83.33333%; } }

.col-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }

@media only screen and (max-width: 1023px) { .col-md-11 { flex: 0 0 91.66667%; max-width: 91.66667%; } }

@media only screen and (max-width: 767px) { .col-sm-11 { flex: 0 0 91.66667%; max-width: 91.66667%; } }

.col-12 { flex: 0 0 100%; max-width: 100%; }

@media only screen and (max-width: 1023px) { .col-md-12 { flex: 0 0 100%; max-width: 100%; } }

@media only screen and (max-width: 767px) { .col-sm-12 { flex: 0 0 100%; max-width: 100%; } }

.out { position: relative; z-index: 1; overflow: hidden; }

.out:before { position: absolute; content: ''; top: 10px; left: 72%; transform: translateX(-500px); width: 1000px; height: 1000px; border-radius: 50%; z-index: -1; pointer-events: none; border: 1px solid rgba(0, 0, 0, 0.05); opacity: 0; -webkit-transition: opacity 0.3s ease-in-out; transition: opacity 0.3s ease-in-out; }

.is-home .out:before { display: none; }

.is-loaded .out:before { opacity: 1; transition-delay: .6s; }

@media only screen and (max-width: 1023px) { .out:before { display: none; } }

.wrap { padding: 228px 0 112px; }

@media only screen and (max-width: 767px) { .wrap { padding: 128px 0 50px; } }

.wrap_pb0 { padding-bottom: 0; }

@media only screen and (max-width: 767px) { .wrap_pb0 { padding-bottom: 0; } }

.inner-lg, .inner { padding: 0 15px; margin: 0 auto; max-width: 1510px; }

.inner { max-width: 1200px; }

.header { position: fixed; top: 0; left: 0; width: 100%; z-index: 100; padding: 20px 0; font-size: 16px; -webkit-transition: transform 0.3s ease-in-out; transition: transform 0.3s ease-in-out; transform: translateY(9px); }

@media only screen and (max-width: 1023px) { .header { font-size: 18px; transform: translateY(0); } }

.header:before { position: absolute; content: ''; top: 50%; margin-top: -44px; left: 0; width: 100%; height: 88px; z-index: -1; -webkit-transition: transform 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out; transition: transform 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out; border-bottom: 1px solid transparent; }

.header.is-fixed { transform: translateY(0); }

.header.is-fixed:before { transform: translateY(-9px); border-color: rgba(0, 0, 0, 0.07); background-color: #fff; }

@media only screen and (max-width: 1023px) { .header.is-fixed:before { transform: translateY(-16px); } }

.header__inner { display: flex; line-height: 1; align-items: center; justify-content: space-between; }

.header__left { width: 10.5%; flex-shrink: 0; }

@media only screen and (max-width: 1259px) { .header__left { width: 8%; } }

@media only screen and (max-width: 1023px) { .header__left { display: none; } }

.header__center { flex-grow: 1; line-height: 0; }

.header__right { padding-top: 2px; display: flex; flex-wrap: wrap; align-items: center; }

@media only screen and (max-width: 1023px) { .header__right { position: absolute; top: 0; left: 0; width: 100%; padding: 100px 0; height: 100vh; opacity: 0; visibility: hidden; -webkit-transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; overflow: auto; flex-direction: column; justify-content: center; background-color: #fff; }
  .is-open-menu .header__right { opacity: 1; visibility: visible; } }

.header__nav:not(:last-child) { margin-right: 100px; }

@media only screen and (max-width: 1259px) { .header__nav:not(:last-child) { margin-right: 50px; } }

@media only screen and (max-width: 1023px) { .header__nav:not(:last-child) { margin-right: 0; width: 100%; } }

@media only screen and (max-width: 1023px) { .header__soc { padding-top: 50px; } }

.header .logo { position: relative; z-index: 1; }

.menu { flex-wrap: wrap; align-items: center; display: flex; margin-right: 20px; }

@media only screen and (max-width: 1023px) { .menu { flex-direction: column; width: 100%; text-align: center; } }

.menu__item { position: relative; color: rgba(0, 0, 0, 0.3); -webkit-transition: color 0.3s ease-in-out; transition: color 0.3s ease-in-out; }

@media only screen and (max-width: 1023px) { .menu__item { width: 100%; display: block; } }

.menu__item:not(:first-child) { margin-left: 38px; }

@media only screen and (max-width: 1023px) { .menu__item:not(:first-child) { margin: 20px 0 0; } }

@media only screen and (max-width: 1023px) { .menu__item.is-open { color: rgba(0, 0, 0, 0.5); } }

.menu__item.is-active { color: rgba(0, 0, 0, 0.7); }

@media only screen and (max-width: 1023px) { .menu__item.is-active { color: rgba(0, 0, 0, 0.7); } }

.no-touch .menu__item:hover { color: rgba(0, 0, 0, 0.7); }

.no-touch .menu__item:hover .submenu { opacity: 1; visibility: visible; }

@media only screen and (max-width: 1259px) { .menu__item > .opClos { display: none; } }

@media only screen and (max-width: 1023px) { .menu__item > .opClos { display: initial; } }

.menu__item > a { color: inherit; }

@media only screen and (max-width: 1023px) { .menu__item > a { padding: 0 15px; } }

.menu__btn { position: relative; margin-right: 7px; font-size: 9px; line-height: 1; -webkit-transition: transform 0.3s ease-in-out; transition: transform 0.3s ease-in-out; background-color: transparent; color: inherit; }

@media only screen and (max-width: 1023px) { .menu__item.is-open .menu__btn { transform: rotate(180deg); } }

.menu__btn:before { position: absolute; content: ''; top: -7px; left: -7px; right: -7px; bottom: -7px; }

.submenu { position: absolute; padding: 8px 0; top: 100%; left: -15px; margin-top: 14px; width: 170px; line-height: 1.25; background-color: #fff; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); -webkit-transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; opacity: 0; visibility: hidden; }

@media only screen and (max-width: 1023px) { .submenu { box-shadow: none; transition: none; display: none; visibility: visible; opacity: 1; position: relative; padding: 0 15px; top: auto; left: auto; width: 100%; font-size: 14px; } }

.submenu:before { position: absolute; content: ''; top: -14px; left: 0; right: 0; height: 14px; }

.submenu li { display: block; }

.submenu li.is-active a { color: rgba(0, 0, 0, 0.7); }

.submenu a { padding: 6px 15px; color: rgba(0, 0, 0, 0.3); -webkit-transition: color 0.3s ease-in-out; transition: color 0.3s ease-in-out; display: block; }

.no-touch .submenu a:hover { color: rgba(0, 0, 0, 0.7); }

.soc { flex-wrap: wrap; display: flex; }

.soc__item:not(:last-child) { margin-right: 30px; }

@media only screen and (max-width: 1023px) { .soc__item:not(:last-child) { margin-right: 20px; } }

.soc a { -webkit-transition: color 0.3s ease-in-out; transition: color 0.3s ease-in-out; font-size: 20px; height: 1px; color: rgba(0, 0, 0, 0.2); }

@media only screen and (max-width: 1023px) { .soc a { font-size: 16px; } }

.no-touch .soc a:hover { color: rgba(0, 0, 0, 0.4); }

.logo { width: 112px; }

.logo, .logo-symbol { vertical-align: middle; display: inline-block; }

.logo img, .logo-symbol img { max-width: 100%; display: block; }

.btn-menu { position: relative; width: 18px; height: 16px; background-color: transparent; display: none; }

@media only screen and (max-width: 1023px) { .btn-menu { display: block; } }

.btn-menu:before { position: absolute; content: ''; top: -10px; left: -10px; right: -10px; bottom: -10px; }

.btn-menu__line { position: absolute; right: 0; width: 19px; height: 2px; transform-origin: top right; transition: transform .3s, opacity .3s; background-color: #000; border-radius: 4px; }

.btn-menu__line:first-child { top: 0; }

.header.is-open-menu .btn-menu__line:first-child { transform: rotate(-45deg); }

.btn-menu__line:nth-child(2) { margin-top: -1px; top: 50%; width: 12px; }

.header.is-open-menu .btn-menu__line:nth-child(2) { opacity: 0; }

.btn-menu__line:last-child { bottom: 0; }

.header.is-open-menu .btn-menu__line:last-child { transform: rotate(45deg) translate(1px, -1px); }

.opClos { color: #ecf0f1; background: transparent; width: 12px; height: 12px; position: relative; font-size: 50px; cursor: pointer; border: 0px; outline: 0px; padding: 0px; display: none; }

.opClos img { width: 12px; position: absolute; }

.opClos .plus, .opClos .minus { color: #fff; width: 12px; height: 12px; line-height: 12px; display: block; position: absolute; top: 0; left: 0; right: 0; bottom: 0; text-align: center; box-sizing: border-box; transition: .3s all ease-out; vertical-align: middle; }

.opClos .plus { opacity: 1; transform: rotate(0deg); left: 0px; top: -5px; }

.opClos.actived .plus { opacity: 0; transform: rotate(90deg); }

.opClos .minus { opacity: 0; transform: rotate(-90deg); left: 6px; top: 7px; }

.opClos.actived .minus { opacity: 1; transform: rotate(0deg); left: 0px; top: -5px; }

[data-anim="text-from-bottom"] { display: block; transform: translateY(102%); }

[data-anim="from-top"] { transform: translateY(-60px); opacity: 0; }

[data-anim="from-bottom"] { transform: translateY(60px); opacity: 0; }

[data-anim="from-left"] { transform: translateX(-100px); opacity: 0; }

[data-anim="from-right"] { transform: translateX(100px); opacity: 0; }

[data-anim="fade"] { opacity: 0; }

[data-anim] { position: relative; }

[data-anim][data-scroll-trigger] { transition: opacity .8s, transform 0.8s; }

[data-anim].is-animate { opacity: 1; transform: translate(0, 0); }

[data-anim-text-parent] { overflow: hidden; display: block; }

[data-accordion-item][accordion-on-init] [data-accordion-container] { display: block; }

@media only screen and (max-width: 1023px) { [data-accordion-item][accordion-on-init] [data-accordion-container] { display: none; } }

[data-accordion-trigger] { cursor: pointer; user-select: none; }

[data-accordion-container] { display: none; }

.hero__inner-lg { position: relative; padding: 166px 0; }

@media only screen and (max-width: 1023px) { .hero__inner-lg { padding: 100px 0; } }

@media only screen and (max-width: 767px) { .hero__inner-lg { padding: 80px 0 50px; } }

.hero__info { position: fixed; top: 0; margin-left: 15px; width: 12px; height: 100vh; max-height: 100%; min-height: 560px; padding-bottom: 50px; font-size: 12px; line-height: 1; letter-spacing: 0.1em; text-transform: uppercase; flex-direction: column; justify-content: center; display: flex; }

@media only screen and (max-width: 1365px) { .hero__info { display: none; } }

.hero__info-title { margin: auto 0; color: rgba(0, 0, 0, 0.7); transform: scale(-1) translateY(-156px); }

.hero__info-text { position: relative; margin-top: auto; color: rgba(0, 0, 0, 0.2); transform: scale(-1); }

.hero__info-text:before { position: absolute; content: ''; left: 50%; top: 100%; margin-top: 15px; height: 50px; width: 1px; background-color: rgba(0, 0, 0, 0.1); }

.hero__info-title, .hero__info-text { writing-mode: vertical-lr; white-space: nowrap; }

.hero__inner { align-items: center; display: flex; }

@media only screen and (max-width: 767px) { .hero__inner { flex-direction: column-reverse; } }

.hero__content { position: relative; margin-bottom: 8px; z-index: 1; width: 53%; }

@media only screen and (max-width: 1259px) { .hero__content { width: 60%; } }

@media only screen and (max-width: 767px) { .hero__content { width: 100%; } }

.hero__title { margin-bottom: 16px; }

.hero__text { max-width: 343px; }

.hero__footer { margin-top: 27px; }

.hero__btn:not(:last-child) { margin-right: 36px; }

@media only screen and (max-width: 767px) { .hero__btn:not(:last-child) { margin-right: 24px; } }

.hero__btn2:not(:last-child) { padding: 10px 20px 9px 24px; margin-right: 36px; }

@media only screen and (max-width: 767px) { .hero__btn2:not(:last-child) { margin-right: 24px; } }

.hero > .animateBtn { background-color: #1c87c9; -webkit-border-radius: 60px; border-radius: 60px; border: none; color: #eeeeee; cursor: pointer; display: inline-block; font-size: 20px; padding: 10px 10px; text-align: center; text-decoration: none; }

.hero__img { position: relative; width: 47%; }

.hero__img:before { position: absolute; content: ''; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 1000px; height: 1000px; border-radius: 50%; z-index: -1; pointer-events: none; border: 1px solid rgba(0, 0, 0, 0.05); opacity: 0; -webkit-transition: opacity 0.3s ease-in-out; transition: opacity 0.3s ease-in-out; }

html.is-loaded .hero__img:before { opacity: 1; transition-delay: .6s; }

@media only screen and (max-width: 1023px) { .hero__img:before { display: none; } }

@media only screen and (max-width: 1259px) { .hero__img { width: 40%; } }

@media only screen and (max-width: 767px) { .hero__img { margin-bottom: 50px; width: 100%; justify-content: center; display: flex; } }

.hero__img img, .hero__img svg { margin: 29px -120px 0 12px; display: block; }

@media only screen and (max-width: 1023px) { .hero__img img, .hero__img svg { margin: 0; max-width: 400px; height: auto; } }

@media only screen and (max-width: 767px) { .hero__img img, .hero__img svg { max-width: 80%; } }

.hero-svg { position: relative; transition: transform .4s ease-out; }

.hero-svg.is-animate { transform: translate(-20px, -20px); transition-delay: .7s; }

.hero-svg__logo-cube { stroke-dasharray: 500; stroke-dashoffset: 500; }

.hero-svg.is-animate .hero-svg__logo-cube { animation: dashCube .5s linear forwards; animation-delay: .3s; }

.hero-svg__logo-line { stroke-dasharray: 1800; stroke-dashoffset: 1800; }

.hero-svg.is-animate .hero-svg__logo-line { animation: dashLine .8s linear forwards; }

.hero-svg__shadow path, .hero-svg__shadow polygon { transform: translate(-20px, -20px); transition: transform .4s ease-out; }

.hero-svg.is-animate .hero-svg__shadow path, .hero-svg.is-animate .hero-svg__shadow polygon { transform: translate(0, 0); transition-delay: .7s; }

.hero-svg__clouds path, .hero-svg__clouds polygon, .hero-svg__cube path, .hero-svg__cube polygon, .hero-svg__semicircles path, .hero-svg__semicircles polygon, .hero-svg__lines path, .hero-svg__lines polygon { opacity: 0; transform: scale(0.99); transition: opacity .3s ease-out, transform  .3s ease-in-out; }

.hero-svg.is-animate .hero-svg__clouds path, .hero-svg.is-animate .hero-svg__clouds polygon, .hero-svg.is-animate .hero-svg__cube path, .hero-svg.is-animate .hero-svg__cube polygon, .hero-svg.is-animate .hero-svg__semicircles path, .hero-svg.is-animate .hero-svg__semicircles polygon, .hero-svg.is-animate .hero-svg__lines path, .hero-svg.is-animate .hero-svg__lines polygon { opacity: 1; transform: scale(1); }

.hero-svg.is-animate .hero-svg__clouds path:nth-child(1) { transition-delay: 0.9s; }

.hero-svg.is-animate .hero-svg__clouds path:nth-child(2) { transition-delay: 1s; }

.hero-svg.is-animate .hero-svg__clouds path:nth-child(3) { transition-delay: 1.1s; }

.hero-svg.is-animate .hero-svg__cube polygon:nth-child(1) { transition-delay: 1.1s; }

.hero-svg.is-animate .hero-svg__cube polygon:nth-child(2) { transition-delay: 1.22s; }

.hero-svg.is-animate .hero-svg__cube polygon:nth-child(3) { transition-delay: 1.34s; }

.hero-svg.is-animate .hero-svg__semicircles path:nth-child(1) { transition-delay: 1.3s; }

.hero-svg.is-animate .hero-svg__semicircles path:nth-child(2) { transition-delay: 1.4s; }

.hero-svg.is-animate .hero-svg__semicircles path:nth-child(3) { transition-delay: 1.5s; }

.hero-svg.is-animate .hero-svg__lines polygon:nth-child(1) { transition-delay: 1.4s; }

.hero-svg.is-animate .hero-svg__lines polygon:nth-child(2) { transition-delay: 1.51s; }

.hero-svg.is-animate .hero-svg__lines polygon:nth-child(3) { transition-delay: 1.62s; }

.hero-svg.is-animate .hero-svg__lines polygon:nth-child(4) { transition-delay: 1.73s; }

.hero-svg.is-animate .hero-svg__lines polygon:nth-child(5) { transition-delay: 1.84s; }

.hero-svg.is-animate .hero-svg__lines polygon:nth-child(6) { transition-delay: 1.95s; }

.hero-svg__petals-third path, .hero-svg__petals-third polygon, .hero-svg__petals-first path, .hero-svg__petals-first polygon, .hero-svg__petals-second path, .hero-svg__petals-second polygon { opacity: 0; transition: opacity .3s ease-out, transform  .3s ease-in-out; transform-origin: center bottom; transform: scale(0.99); }

.hero-svg.is-animate .hero-svg__petals-third path, .hero-svg.is-animate .hero-svg__petals-third polygon, .hero-svg.is-animate .hero-svg__petals-first path, .hero-svg.is-animate .hero-svg__petals-first polygon, .hero-svg.is-animate .hero-svg__petals-second path, .hero-svg.is-animate .hero-svg__petals-second polygon { opacity: 1; transform: scale(1); }

.hero-svg.is-animate .hero-svg__petals-third path, .hero-svg.is-animate .hero-svg__petals-third polygon { transition-delay: 1.7s; }

.hero-svg.is-animate .hero-svg__petals-first path, .hero-svg.is-animate .hero-svg__petals-first polygon { transition-delay: 1.85s; }

.hero-svg.is-animate .hero-svg__petals-second path, .hero-svg.is-animate .hero-svg__petals-second polygon { transition-delay: 2s; }

.hero-svg__person-bottom-right, .hero-svg__person-bottom-left, .hero-svg__person-top { opacity: 0; transition: opacity .4s ease-in-out; }

.hero-svg.is-animate .hero-svg__person-bottom-right, .hero-svg.is-animate .hero-svg__person-bottom-left, .hero-svg.is-animate .hero-svg__person-top { opacity: 1; }

.hero-svg.is-animate .hero-svg__person-top { transition-delay: 1.9s; }

.hero-svg.is-animate .hero-svg__person-bottom-left { transition-delay: 2.2s; }

.hero-svg.is-animate .hero-svg__person-bottom-right { transition-delay: 2.5s; }

@keyframes dashLine { from { stroke-dashoffset: 1800; }
  to { stroke-dashoffset: 0; } }

@keyframes dashCube { from { stroke-dashoffset: 500; }
  to { stroke-dashoffset: 0; } }

@keyframes slideInFromLeft { 0% { transform: translateX(-800%); }
  100% { transform: translateX(0); } }

@keyframes glowing { 0% { background-color: white;
    color: #fff;
    box-shadow: 0 0 3px #dde3f9; }
  100% { background-color: #dde3f9;
    color: #dde3f9;
    box-shadow: 0 0 3px #dde3f9; } }

.arr_btn_animate { animation: 1s ease-out 1s 1 slideInFromLeft; display: inherit !important; }

.arr_btn_glow_animate { animation: 1s ease-out 1s 1 glowing; }

.arr_btn { width: 14px; }

.service { padding: 80px 0 50px; }

@media only screen and (max-width: 1023px) { .service { padding: 50px 0; } }

.service__inner { align-items: center; display: flex; }

@media only screen and (max-width: 767px) { .service__inner { align-items: flex-start; flex-direction: column; } }

.service__sliderimg { padding-right: 30px; width: 51.3%; }

@media only screen and (max-width: 767px) { .service__sliderimg { margin-bottom: 30px; width: 100%; max-width: 320px; } }

.service__slidernav { width: 48.7%; }

@media only screen and (max-width: 767px) { .service__slidernav { width: 100%; } }

.service__topic { margin-bottom: 35px; justify-content: space-between; display: flex; flex-wrap: wrap; letter-spacing: 0.1em; }

@media only screen and (max-width: 1023px) { .service__topic { margin-bottom: 20px; } }

.service__name { color: rgba(0, 0, 0, 0.5); font-size: 14px; line-height: 1.35; text-transform: uppercase; }

.service__counter { font-size: 14px; color: rgba(0, 0, 0, 0.3); }

.sliderimg.slick-initialized { opacity: 1; visibility: visible; }

.sliderimg img { max-width: 100%; display: block; }

.slidernav .slick-dots { margin-top: 55px; font-size: 0; }

@media only screen and (max-width: 1023px) { .slidernav .slick-dots { margin-top: 40px; } }

.slidernav .slick-dots li { vertical-align: top; display: inline-block; }

.slidernav .slick-dots li:not(:first-child) { margin-left: 20px; }

.slidernav .slick-dots li.slick-active button { opacity: 1; background-color: #000; }

.slidernav .slick-dots li button { position: relative; -webkit-transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out; transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out; height: 10px; width: 10px; font-size: 0; border: 1px solid #000; opacity: .3; background-color: transparent; }

.no-touch .slidernav .slick-dots li button:hover { opacity: .6; }

.slidernav .slick-dots li button:before { position: absolute; content: ''; top: -8px; right: -8px; left: -8px; bottom: -8px; }

.slidernav__title { margin-bottom: 30px; }

@media only screen and (max-width: 1023px) { .slidernav__title { margin-bottom: 20px; } }

.slidernav__text { max-width: 494px; }

@media only screen and (max-width: 767px) { .slidernav__text { max-width: 100%; } }

.slidernav__footer { margin-top: 38px; line-height: 1; }

@media only screen and (max-width: 1023px) { .slidernav__footer { margin-top: 30px; } }

.dropdown-content { display: none; position: absolute; position-background-color: #fff; position-min-width: 115px; position-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); position-z-index: 1; position-padding: 10px; margin-top: 10px; }

.dropdown a { padding: 10px; color: #000; }

.dropdown:hover .dropdown-content { display: grid; }

.previousbtn { position: absolute; z-index: 1000; top: 50%; left: -65px; color: #BFAFB2; }

.nextbtn { position: absolute; z-index: 1000; top: 50%; right: 0px; color: #BFAFB2; }

.nextbtn.slick-arrow { bottom: -20px; top: initial; left: initial; position: absolute; right: 0px; }

.previousbtn.slick-arrow { bottom: -20px; top: initial; right: 40px; left: inherit; }

.service__slidernav { position: relative; }

.cases { padding: 110px 0 80px; }

@media only screen and (max-width: 1023px) { .cases { padding: 50px 0; } }

.cases__titleinfo { margin-bottom: 35px; }

.cases__description { max-width: 471px; }

@media only screen and (max-width: 767px) { .cases__description { max-width: 100%; } }

.cases__wrap { padding-right: 70px; }

@media only screen and (max-width: 1023px) { .cases__wrap { padding-right: 0; } }

.cases__wrap:not(:first-child) { margin-top: 108px; }

@media only screen and (max-width: 767px) { .cases__wrap:not(:first-child) { margin-top: 70px; } }

.cases__container { position: relative; transition: opacity 0.3s, visibility 0.3s; margin: 0 -50px; }

@media only screen and (max-width: 1023px) { .cases__container { margin: 0 -30px; } }

.cases__item { padding: 0 50px; width: 50%; }

@media only screen and (max-width: 1023px) { .cases__item { padding: 0 30px; } }

.cases__item:not(:first-child) { padding-top: 100px; }

@media only screen and (max-width: 767px) { .cases__item:not(:first-child) { padding-top: 50px; } }

.cases__item:nth-child(2) { padding-top: 145px; }

@media only screen and (max-width: 767px) { .cases__item:nth-child(2) { padding-top: 50px; } }

@media only screen and (max-width: 767px) { .cases__item { width: 100%; } }

.cases__item_text { padding: 117px 0; align-items: center; justify-content: center; display: flex; }

.cases__item_text:not(:first-child) { padding-top: 204px; }

.cases__item-wrap { max-width: 295px; }

.cases__title-info { margin-bottom: 29px; font-size: 30px; font-weight: 400; line-height: 1.6; color: #000; }

@media only screen and (max-width: 767px) { .cases__col:not(:last-child) { margin-bottom: 20px; } }

.case { color: rgba(0, 0, 0, 0.4); display: block; }

.no-touch .case:hover .case__image-wrap { transform: scale(0.97); }

.no-touch .case:hover .case__image { transform: scale(1.1); }

.case__image-wrap { margin-bottom: 50px; overflow: hidden; -webkit-transition: transform 0.3s ease-in-out; transition: transform 0.3s ease-in-out; backface-visibility: hidden; }

@media only screen and (max-width: 767px) { .case__image-wrap { margin-bottom: 20px; } }

.case__image { -webkit-transition: transform 0.3s ease-in-out; transition: transform 0.3s ease-in-out; padding-bottom: 86%; background-size: cover; background-position: center; }

.case__image img { max-width: 100%; display: block; }

.case__title { margin-bottom: 22px; }

@media only screen and (max-width: 767px) { .case__title { margin-bottom: 15px; } }

.case__text { max-width: 378px; }

.case__text:not(:last-child) { margin-bottom: 28px; }

@media only screen and (max-width: 767px) { .case__text:not(:last-child) { margin-bottom: 20px; } }

.js-masonry-width { width: 50%; }

@media only screen and (max-width: 767px) { .js-masonry-width { width: 100%; } }

.whatwedo { padding: 115px 0; }

@media only screen and (max-width: 1023px) { .whatwedo { padding: 100px 0 50px; } }

.whatwedo__inner { align-items: center; display: flex; }

@media only screen and (max-width: 767px) { .whatwedo__inner { align-items: flex-start; flex-direction: column; } }

.whatwedo__titleinfo { margin-bottom: 12px; }

.whatwedo__title { margin-bottom: 20px; }

@media only screen and (max-width: 1023px) { .whatwedo__title { margin-bottom: 20px; } }

.whatwedo__items { margin: 0 -33px; }

.whatwedo__col { padding: 0 33px; display: flex; flex-direction: column; justify-content: space-between; }

.whatwedo__img { margin-bottom: 34px; align-items: center; display: flex; }

.whatwedo__img img { max-width: 100%; top: 50%; left: 50%; height: 150px; width: 150px; }

@media only screen and (max-width: 1023px) { .whatwedo__img img { height: 80px; width: 80px; } }

.whatwedo__title-sm { margin-bottom: 26px; font-size: 20px; font-weight: 900; line-height: 1.35; color: #000; }

.whatwedo__footer { margin-top: 28px; }

.whatwedo__sliderimg { padding-top: 30px; padding-left: 10px; width: 40%; }

@media only screen and (max-width: 767px) { .whatwedo__sliderimg { margin-bottom: 30px; width: 100%; max-width: 320px; display: none; } }

.whatwedo__sliderimgmobile { padding-top: 30px; padding-left: 10px; width: 40%; display: none; }

@media only screen and (max-width: 767px) { .whatwedo__sliderimgmobile { margin-bottom: 30px; width: 100%; max-width: 320px; display: block; } }

.whatwedo__slidermiddle { width: 10%; min-width: 10px; }

@media only screen and (max-width: 767px) { .whatwedo__slidermiddle { display: none; } }

.whatwedo__slidernav { width: 60%; }

@media only screen and (max-width: 767px) { .whatwedo__slidernav { width: 100%; } }

.whatwedo__topic { margin-bottom: 35px; justify-content: space-between; display: flex; flex-wrap: wrap; letter-spacing: 0.1em; }

@media only screen and (max-width: 1023px) { .whatwedo__topic { margin-bottom: 20px; } }

.whatwedo__name { color: rgba(0, 0, 0, 0.5); font-size: 14px; line-height: 1.35; text-transform: uppercase; }

.whatwedo__counter { font-size: 14px; color: rgba(0, 0, 0, 0.3); }

.consulting { padding: 96px 0 80px; }

@media only screen and (max-width: 1023px) { .consulting { padding: 50px 0; } }

.consulting__col:not(:last-child) { margin-bottom: 40px; }

.consulting__wrap { max-width: 460px; }

@media only screen and (max-width: 767px) { .consulting__wrap { max-width: 100%; } }

.consulting__titleinfo { margin-bottom: 35px; }

.consulting__title { margin-bottom: 30px; }

@media only screen and (max-width: 1023px) { .consulting__title { margin-bottom: 20px; } }

.consulting__footer { margin-top: 35px; }

.consulting__concept { counter-reset: list; }

.consulting__item { position: relative; padding-left: 70px; }

@media only screen and (max-width: 1023px) { .consulting__item { padding-left: 44px; } }

.consulting__item:not(:last-child) { margin-bottom: 39px; }

@media only screen and (max-width: 1023px) { .consulting__item:not(:last-child) { margin-bottom: 20px; } }

.consulting__item:before { position: absolute; top: 0; left: 0; counter-increment: list; content: counter(list); font-size: 24px; font-weight: 900; line-height: 1.375; color: #000; opacity: 0.3; }

.consulting__item:nth-of-type(-n+9):before { content: "0" counter(list); }

.consulting__titlexs { margin-bottom: 24px; }

@media only screen and (max-width: 1023px) { .consulting__titlexs { margin-bottom: 15px; } }

.consulting__list li { position: relative; display: block; }

.consulting__list li:before { content: '- '; }

.clients { padding: 104px 0 120px; }

@media only screen and (max-width: 1023px) { .clients { padding: 50px 0; } }

.clients__titleinfo { margin-bottom: 35px; }

.clients__title { margin-bottom: 30px; }

.clients__items:not(:first-child) { margin-top: 37px; }

@media only screen and (max-width: 767px) { .clients__items:not(:first-child) { margin-top: 10px; } }

.clients__row { align-items: center; justify-content: center; flex-wrap: wrap; display: flex; }

@media only screen and (max-width: 1023px) { .clients__row { display: inline; } }

.clients__item { margin: 40px 25px 0; }

@media only screen and (max-width: 1023px) { .clients__item { vertical-align: middle; display: inline-block; } }

@media only screen and (max-width: 767px) { .clients__item { margin: 20px 10px 0; } }

.clients__item img { display: block; }

.workwithus { padding: 120px 0; }

@media only screen and (max-width: 767px) { .workwithus { padding: 50px 0; } }

.workwithus__titleinfo { margin-bottom: 30px; }

.workwithus__title { margin-bottom: 38px; }

@media only screen and (max-width: 767px) { .workwithus__row { flex-direction: column; } }

@media only screen and (max-width: 767px) { .workwithus__col:not(:last-child) { margin-bottom: 20px; } }

.workwithus__btn:not(:last-child) { margin-right: 40px; }

@media only screen and (max-width: 767px) { .workwithus__btn:not(:last-child) { margin-right: 23px; } }

.workwithus__img { margin: -143px 0; display: flex; justify-content: flex-end; }

@media only screen and (max-width: 767px) { .workwithus__img { margin: 30px 0 0; display: none; } }

.workwithus__img img, .workwithus__img svg { display: block; margin-right: -71px; }

@media only screen and (max-width: 767px) { .workwithus__img img, .workwithus__img svg { margin: 0; max-width: 100%; } }

.workwithus-svg { position: relative; transition: transform .4s ease-out; }

.workwithus-svg.is-animate { transform: translate(-8px, -8px); transition-delay: .7s; }

.workwithus-svg__logo-cube { stroke-dasharray: 800; stroke-dashoffset: 800; transition: stroke-dashoffset 1.5s ease-out; }

.workwithus-svg.is-animate .workwithus-svg__logo-cube { stroke-dashoffset: 0; transition-delay: .5s; }

.workwithus-svg__logo-line { stroke-dasharray: 1600; stroke-dashoffset: 1600; transition: stroke-dashoffset 3s ease-out; }

.workwithus-svg.is-animate .workwithus-svg__logo-line { stroke-dashoffset: 0; }

.workwithus-svg__circle { stroke: transparent; transition: stroke .3s ease-out; }

.workwithus-svg.is-animate .workwithus-svg__circle { stroke: #000; transition-delay: 1s; }

.workwithus-svg__shadow { transform: translate(-10px, -10px); transition: transform .4s ease-out; }

.workwithus-svg.is-animate .workwithus-svg__shadow { transform: translate(0, 0); transition-delay: .7s; }

.workwithus-svg__anim-third rect, .workwithus-svg__anim-first rect, .workwithus-svg__anim-second path { opacity: 0; transition: opacity .3s ease-out; }

.workwithus-svg.is-animate .workwithus-svg__anim-third rect, .workwithus-svg.is-animate .workwithus-svg__anim-first rect, .workwithus-svg.is-animate .workwithus-svg__anim-second path { opacity: 1; }

.workwithus-svg.is-animate .workwithus-svg__anim-first rect:nth-child(1) { transition-delay: 1s; }

.workwithus-svg.is-animate .workwithus-svg__anim-first rect:nth-child(2) { transition-delay: 1.1s; }

.workwithus-svg.is-animate .workwithus-svg__anim-first rect:nth-child(3) { transition-delay: 1.2s; }

.workwithus-svg.is-animate .workwithus-svg__anim-second path:nth-child(1) { transition-delay: 1.1s; }

.workwithus-svg.is-animate .workwithus-svg__anim-second path:nth-child(2) { transition-delay: 1.2s; }

.workwithus-svg.is-animate .workwithus-svg__anim-second path:nth-child(3) { transition-delay: 1.3s; }

.workwithus-svg.is-animate .workwithus-svg__anim-third rect:nth-child(1) { transition-delay: 1.3s; }

.workwithus-svg.is-animate .workwithus-svg__anim-third rect:nth-child(2) { transition-delay: 1.4s; }

.workwithus-svg.is-animate .workwithus-svg__anim-third rect:nth-child(3) { transition-delay: 1.5s; }

.workwithus-svg.is-animate .workwithus-svg__anim-third rect:nth-child(4) { transition-delay: 1.6s; }

.workwithus-svg.is-animate .workwithus-svg__anim-third rect:nth-child(5) { transition-delay: 1.7s; }

.workwithus-svg.is-animate .workwithus-svg__anim-third rect:nth-child(6) { transition-delay: 1.8s; }

@keyframes dashLogoCube { from { stroke-dashoffset: 800; }
  to { stroke-dashoffset: 0; } }

article img, .article img { max-width: 100%; display: block; }

article img:not(:first-child), .article img:not(:first-child) { margin-top: 65px; }

@media only screen and (max-width: 767px) { article img:not(:first-child), .article img:not(:first-child) { margin-top: 40px; } }

article img:not(:last-child), .article img:not(:last-child) { margin-bottom: 65px; }

@media only screen and (max-width: 767px) { article img:not(:last-child), .article img:not(:last-child) { margin-bottom: 40px; } }

article p:not(:last-child), .article p:not(:last-child) { margin-bottom: 35px; }

@media only screen and (max-width: 767px) { article p:not(:last-child), .article p:not(:last-child) { margin-bottom: 25px; } }

article .label, .article .label { margin-bottom: 8px; }

article__topic, .article__topic { margin-bottom: 24px; align-items: center; }

@media only screen and (max-width: 767px) { article__topic, .article__topic { margin-bottom: 10px; } }

article__topic-col, .article__topic-col { height: 100%; display: flex; }

article__topic-col:last-child, .article__topic-col:last-child { justify-content: center; }

@media only screen and (max-width: 767px) { article__topic-col:last-child, .article__topic-col:last-child { justify-content: flex-start; } }

article__col-disc, .article__col-disc { margin-top: 20px; }

article__row:not(:first-child), .article__row:not(:first-child) { margin-top: 112px; }

@media only screen and (max-width: 767px) { article__row:not(:first-child), .article__row:not(:first-child) { margin-top: 90px; } }

article__row:not(:last-child), .article__row:not(:last-child) { margin-bottom: 112px; }

@media only screen and (max-width: 767px) { article__row:not(:last-child), .article__row:not(:last-child) { margin-bottom: 90px; } }

@media only screen and (max-width: 767px) { article__col:not(:last-child), .article__col:not(:last-child) { margin-bottom: 30px; } }

article__titleinfo, .article__titleinfo { margin-bottom: 17px; }

article__title, .article__title { margin-bottom: 31px; }

article__description, .article__description { max-width: 470px; font-size: 20px; font-weight: 400; line-height: 1.6; color: rgba(0, 0, 0, 0.7); }

@media only screen and (max-width: 767px) { article__description, .article__description { max-width: 100%; } }

article__text, .article__text { max-width: 480px; }

@media only screen and (max-width: 767px) { article__text, .article__text { max-width: 100%; } }

article__text:not(:first-child), .article__text:not(:first-child) { margin-top: 20px; }

article__buttons, .article__buttons { margin-top: 35px; }

@media only screen and (max-width: 767px) { article__buttons, .article__buttons { margin-top: 20px; } }

article__btn:not(:last-child), .article__btn:not(:last-child) { margin-right: 40px; }

@media only screen and (max-width: 767px) { article__btn:not(:last-child), .article__btn:not(:last-child) { margin-right: 24px; } }

@media only screen and (max-width: 1023px) { .img-logo { max-width: 50% !important; } }

.img-hero { margin-right: -248px; }

@media only screen and (max-width: 767px) { .img-hero { margin-right: 0; } }

.imagelist { margin-top: 20px; display: flex; }

.imagelist li { margin-top: 20px; }

@media only screen and (max-width: 767px) { .imagelist li { margin-top: 10px; } }

.imagelist li:not(:last-child) { margin-right: 40px; }

@media only screen and (max-width: 767px) { .imagelist li:not(:last-child) { margin-right: 24px; } }

.footer { padding: 111px 0; font-size: 14px; line-height: 1.85; }

@media only screen and (max-width: 1023px) { .footer { padding-top: 50px; } }

@media only screen and (max-width: 767px) { .footer { padding: 50px 0; } }

.footer__left { width: 33.4%; display: flex; flex-direction: column; justify-content: space-between; }

@media only screen and (max-width: 767px) { .footer__left { width: 100%; } }

.footer__center { width: 50%; }

@media only screen and (max-width: 767px) { .footer__center { display: none; } }

.footer__col2 { width: 30%; }

@media only screen and (max-width: 767px) { .footer__col2 { display: none; } }

.footer__col3 { width: 20%; }

@media only screen and (max-width: 767px) { .footer__col3 { display: none; } }

.footer__right { width: 16.6%; }

@media only screen and (max-width: 767px) { .footer__right { display: none; } }

.footer__logo { margin-bottom: 35px; vertical-align: top; display: inline-block; }

@media only screen and (max-width: 767px) { .footer__logo { margin-bottom: 20px; } }

.footer__logo img { width: 160px; max-width: 100%; display: block; }

.footer__description { margin-bottom: 25px; max-width: 264px; font-size: 12px; line-height: 1.9; }

@media only screen and (max-width: 767px) { .footer__description { margin-bottom: 10px; max-width: 100%; } }

.footer__email { color: #002DDC; -webkit-transition: opacity 0.3s ease-in-out; transition: opacity 0.3s ease-in-out; }

.no-touch .footer__email:hover { opacity: .8; }

.footer__soc:not(:first-child) { margin-top: 41px; }

.footer__copy { margin-top: 15px; }

.footer__title { margin-bottom: 6px; font-size: 12px; font-weight: 900; line-height: 1.2; letter-spacing: 0.1em; color: #000; }

.footer__list:not(:last-child) { margin-bottom: 33px; }

.footer__center .footer__list { column-count: 2; column-gap: 66px; }

@media only screen and (max-width: 1023px) { .footer__center .footer__list { column-gap: 30px; } }

.footer__list li { padding: 4px 0; }

.footer__list li.is-active a { color: rgba(0, 0, 0, 0.9); }

.footer__list a { font-size: 14px; font-weight: 400; line-height: 1.857; color: rgba(0, 0, 0, 0.5); -webkit-transition: color 0.3s ease-in-out; transition: color 0.3s ease-in-out; }

.no-touch .footer__list a:hover { color: rgba(0, 0, 0, 0.9); }
