.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	padding: 20px 0;
	font-size: 16px;
	@include tr(transform);
	transform: translateY(9px);
	@include md {
		font-size: 18px;
		transform: translateY(0); }
	&:before {
		position: absolute;
		content: '';
		top: 50%;
		margin-top: -44px;
		left: 0;
		width: 100%;
		height: 88px;
		z-index: -1;
		@include tr(transform, border-color, background-color);
		border-bottom: 1px solid transparent; }
	&.is-fixed {
		transform: translateY(0);
		&:before {
			transform: translateY(-9px);
			border-color: rgba(0, 0, 0, 0.07);
			background-color: $white;
			@include md {
				transform: translateY(-16px); } } }
	&__inner {
		display: flex;
		line-height: 1;
		align-items: center;
		justify-content: space-between; }
	&__left {
		width: 10.5%;
		flex-shrink: 0;
		@include lg {
			width: 8%; }
		@include md {
			display: none; } }
	&__center {
		flex-grow: 1;
		line-height: 0; }
	&__right {
		padding-top: 2px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@include md {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			padding: 100px 0;
			height: 100vh;
			opacity: 0;
			visibility: hidden;
			@include tr(opacity, visibility);
			overflow: auto;
			flex-direction: column;
			justify-content: center;
			background-color: $white;
			.is-open-menu & {
				opacity: 1;
				visibility: visible; } } }
	&__nav {
		&:not(:last-child) {
			margin-right: 100px;
			@include lg {
				margin-right: 50px; }
			@include md {
				margin-right: 0;
				width: 100%; } } }
	&__soc {
		@include md {
			padding-top: 50px; } }
	.logo {
		position: relative;
		z-index: 1; } }

.menu {
	flex-wrap: wrap;
	align-items: center;
	display: flex;
	padding 10 300px {}
	margin-right: 20px;
	@include md {
		flex-direction: column;
		width: 100%;
		text-align: center; }
	&__item {
		position: relative;
		color: rgba($black, .3);
		@include tr(color);
		@include md {
			width: 100%;
			display: block; }
		&:not(:first-child) {
			margin-left: 38px;
			@include md {
				margin: 20px 0 0; } }
		&.is-open {
			@include md {
				color: rgba($black, .5); } }
		&.is-active {
			color: rgba($black, .7);
			@include md {
				color: rgba($black, .7); } }
		@include hover {
			color: rgba($black, .7);
			.submenu {
				opacity: 1;
				visibility: visible; } }
		& > .opClos {
			@include lg {
				display: none; }
			@include md {
				display: initial; } }
		& > a {
			color: inherit;
			@include md {
				padding: 0 15px; } } }
	&__btn {
		position: relative;
		margin-right: 7px;
		font-size: 9px;
		line-height: 1;
		@include tr(transform);
		background-color: transparent;
		color: inherit;
		.menu__item.is-open & {
			@include md {
				@include rotate(180); } }
		&:before {
			position: absolute;
			content: '';
			top: -7px;
			left: -7px;
			right: -7px;
			bottom: -7px; } } }

.submenu {
	position: absolute;
	padding: 8px 0;
	top: 100%;
	left: -15px;
	margin-top: 14px;
	width: 170px;
	line-height: 1.25;
	background-color: $white;
	@include lg;
	box-shadow: 0px 4px 4px rgba($black, 0.1);
	@include tr(opacity, visibility);
	opacity: 0;
	visibility: hidden;
	@include md {
		box-shadow: none;
		transition: none;
		display: none;
		visibility: visible;
		opacity: 1;
		position: relative;
		padding: 0 15px;
		top: auto;
		left: auto;
		width: 100%;
		font-size: 14px; }
	&:before {
		position: absolute;
		content: '';
		top: -14px;
		left: 0;
		right: 0;
		height: 14px; }
	li {
		display: block;
		&.is-active {
			a {
				color: rgba($black, .7); } } }
	a {
		padding: 6px 15px;
		color: rgba($black, .3);
		@include tr(color);
		display: block;
		@include hover {
			color: rgba($black, .7); } } }
.soc {
	flex-wrap: wrap;
	display: flex;
	&__item {
		&:not(:last-child) {
			margin-right: 30px;
			@include md {
				margin-right: 20px; } } }
	a {
		@include tr(color);
		font-size: 20px;
		height: 1px;
		color: rgba($black, .2);
		@include md {
			font-size: 16px; }
		@include hover {
			color: rgba($black, .4); } } }

.logo {
	width: 112px; }
.logo,
.logo-symbol {
	vertical-align: middle;
	display: inline-block;
	img {
		max-width: 100%;
		display: block; } }

.btn-menu {
	position: relative;
	width: 18px;
	height: 16px;
	background-color: transparent;
	display: none;
	@include md {
		display: block; }
	&:before {
		position: absolute;
		content: '';
		top: -10px;
		left: -10px;
		right: -10px;
		bottom: -10px; }
	&__line {
		position: absolute;
		right: 0;
		width: 19px;
		height: 2px;
		transform-origin: top right;
		transition: transform .3s, opacity .3s;
		background-color: $black;
		border-radius: 4px;
		&:first-child {
			top: 0;
			.header.is-open-menu & {
				transform: rotate(-45deg); } }
		&:nth-child(2) {
			margin-top: -1px;
			top: 50%;
			width: 12px;
			.header.is-open-menu & {
				opacity: 0; } }
		&:last-child {
			bottom: 0;

			.header.is-open-menu & {
				transform: rotate(45deg) translate(1px,-1px); } } } }
.opClos {
	color: #ecf0f1;
	background: transparent;
	width: 12px;
	height: 12px;
	position: relative;
	font-size: 50px;
	cursor: pointer;
	border: 0px;
	outline: 0px;
	padding: 0px;
	display: none; }

.opClos img {
	width: 12px;
	position: absolute; }

.opClos .plus, .opClos .minus {
	color: #fff;
	width: 12px;
	height: 12px;
	line-height: 12px;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	box-sizing: border-box;
	transition: .3s all ease-out;
	vertical-align: middle; }

.opClos .plus {
	opacity: 1;
	transform: rotate(0deg);
	left: 0px;
	top: -5px; }

.opClos.actived .plus {
	opacity: 0;
	transform: rotate(90deg); }

.opClos .minus {
	opacity: 0;
	transform: rotate(-90deg);
	left: 6px;
	top: 7px; }

.opClos.actived .minus {
	opacity: 1;
	transform: rotate(0deg);
	left: 0px;
	top: -5px; }

