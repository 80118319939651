//FONTS
@mixin fontpath($name, $name1, $file) {
	src: local($name), local($name1), url("../fonts/" + $file + ".woff2") format("woff2"), url("../fonts/" + $file + ".woff") format("woff"); }
@mixin fontdefault($style, $weight, $name) {
	font-family: $name;
	font-style: $style;
	font-weight: $weight; }

$avenir: 'Avenir';

@font-face {
  @include fontdefault(normal, 400, $avenir);
  @include fontpath('Avenir', 'Avenir-Roman', Avenir-Roman); }
@font-face {
  @include fontdefault(normal, 500, $avenir);
  @include fontpath('Avenir Medium', 'Avenir-Medium', Avenir-Medium); }
@font-face {
  @include fontdefault(normal, 800, $avenir);
  @include fontpath('Avenir Heavy', 'Avenir-Heavy', Avenir-Heavy); }
@font-face {
  @include fontdefault(normal, 900, $avenir);
  @include fontpath('Avenir Black', 'Avenir-Black', Avenir-Black); }

// @font-face
//   +fontdefault(italic, 400, $avenir)
//   +fontpath('', '', )
