// CUSTOM COLUMN SIZES

// $column-sizes: 5, 10, 15, 18, 20, 25, 30, 33.33, 35, 40, 45, 46, 50, 55, 54, 60, 65, 66.66, 70, 72, 80, 82, 85, 90, 100
// $padding: 15px

// @each $size in $column-sizes
//   .col-#{round($size)}
//     flex: 0 0 $size * 1%
//     min-width: $size
//     padding-left: $padding
//     padding-right: $padding

// .row
//   display: flex
//   flex-wrap: wrap
//   margin: 0 -#{$padding}

// [class*='col']
//   padding: 0 $padding

// GENERATE GRID EXAMPLE
// CUSTOM PADDING ON BREAKPOINTS - NOT INCLUDED IN MIXIN
@include generate_grid($columns: 12, $sizeXs: false, $sizeSm: true, $sizeMd: true, $sizeLg: false, $padding: 15px);

.out {
	position: relative;
	z-index: 1;
	overflow: hidden;
	&:before {
		position: absolute;
		content: '';
		top: 10px;
		left: 72%;
		transform: translateX(-500px);
		width: 1000px;
		height: 1000px;
		border-radius: 50%;
		z-index: -1;
		pointer-events: none;
		border: 1px solid rgba(0, 0, 0, 0.05);
		opacity: 0;
		@include tr(opacity);
		.is-home & {
			display: none; }
		.is-loaded & {
			opacity: 1;
			transition-delay: .6s; }
		@include md {
			display: none; } } }

.wrap {
	padding: 228px 0 112px;
	@include sm {
		padding: 128px 0 50px; }
	&_pb0 {
		padding-bottom: 0;
		@include sm {
			padding-bottom: 0; } } }

.inner-lg,
.inner {
	padding: 0 15px;
	margin: 0 auto;
	max-width: 1510px; }

.inner {
	max-width: 1200px; }
