.clients {
	padding: 104px 0 120px;
	@include md {
		padding: 50px 0; }
	&__titleinfo {
		margin-bottom: 35px; }
	&__title {
		margin-bottom: 30px; }
	&__items {
		&:not(:first-child) {
			margin-top: 37px;
			@include sm {
				margin-top: 10px; } } }
	&__row {
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		display: flex;
		@include md {
			display: inline; } }
	&__item {
		margin: 40px 25px 0;
		@include md {
			vertical-align: middle;
			display: inline-block; }
		@include sm {
			margin: 20px 10px 0; }
		img {
			display: block; } } }
