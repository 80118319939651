[data-anim="text-from-bottom"] {
  display: block;
  transform: translateY(102%); }

[data-anim="from-top"] {
  transform: translateY(-60px);
  opacity: 0; }

[data-anim="from-bottom"] {
  transform: translateY(60px);
  opacity: 0; }

[data-anim="from-left"] {
  transform: translateX(-100px);
  opacity: 0; }
[data-anim="from-right"] {
  transform: translateX(100px);
  opacity: 0; }

[data-anim="fade"] {
  opacity: 0; }

[data-anim] {
  position: relative;
  &[data-scroll-trigger] {
    transition: opacity .8s, transform 0.8s; }
  &.is-animate {
    opacity: 1;
    transform: translate(0,0); } }

[data-anim-text-parent] {
  overflow: hidden;
  display: block; }
