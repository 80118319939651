.service {
	padding: 80px 0 50px;
	@include md {
		padding: 50px 0; }
	&__inner {
		align-items: center;
		display: flex;
		@include sm {
			align-items: flex-start;
			flex-direction: column; } }
	&__sliderimg {
		padding-right: 30px;
		width: 51.3%;
		@include sm {
			margin-bottom: 30px;
			width: 100%;
			max-width: 320px; } }
	&__slidernav {
		width: 48.7%;
		@include sm {
			width: 100%; } }
	&__topic {
		margin-bottom: 35px;
		justify-content: space-between;
		display: flex;
		flex-wrap: wrap;
		letter-spacing: 0.1em;
		@include md {
			margin-bottom: 20px; } }
	&__name {
		color: rgba($black, .5);
		font-size: 14px;
		line-height: 1.35;
		text-transform: uppercase; }
	&__counter {
		font-size: 14px;
		color: rgba($black, .3); } }

.sliderimg {
	&.slick-initialized {
		opacity: 1;
		visibility: visible; }
	img {
		max-width: 100%;
		display: block; } }

.slidernav {
	.slick-dots {
		margin-top: 55px;
		font-size: 0;
		@include md {
			margin-top: 40px; }
		li {
			vertical-align: top;
			display: inline-block;
			&:not(:first-child) {
				margin-left: 20px; }
			&.slick-active button {
				opacity: 1;
				background-color: $black; }
			button {
				position: relative;
				@include tr(background-color, opacity);
				height: 10px;
				width: 10px;
				font-size: 0;
				border: 1px solid $black;
				opacity: .3;
				background-color: transparent;
				@include hover {
					opacity: .6; }
				&:before {
					position: absolute;
					content: '';
					top: -8px;
					right: -8px;
					left: -8px;
					bottom: -8px; } } } }
	&__title {
		margin-bottom: 30px;
		@include md {
			margin-bottom: 20px; } }
	&__text {
		max-width: 494px;
		@include sm {
			max-width: 100%; } }
	&__footer {
		margin-top: 38px;
		@include md {
			margin-top: 30px; }
		line-height: 1; } }

.dropdown-content {
	display: none;
	position: absolute {
    background-color: #fff;
    min-width: 115px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 10px; }
	margin-top: 10px; }

.dropdown a {
	padding: 10px;
	color: #000; }



.dropdown:hover .dropdown-content {
	display: grid; }

.previousbtn {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: -65px;
    color: #BFAFB2; }

.nextbtn {
    position: absolute;
    z-index: 1000;
    top: 50%;
    right: 0px;
    color: #BFAFB2; }

.nextbtn.slick-arrow {
	bottom: -20px;
	top: initial;
	left: initial;
	position: absolute;
	right: 0px; }

.previousbtn.slick-arrow {
	bottom: -20px;
	top: initial;
	right: 40px;
	left: inherit; }

.service__slidernav {
	position: relative; }
