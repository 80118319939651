.hero {
	&__inner-lg {
		position: relative;
		padding: 166px 0;
		@include md {
			padding: 100px 0; }
		@include sm {
			padding: 80px 0 50px; } }
	&__info {
		position: fixed;
		top: 0;
		margin-left: 15px;
		width: 12px;
		height: 100vh;
		max-height: 100%;
		min-height: 560px;
		padding-bottom: 50px;
		font-size: 12px;
		line-height: 1;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		flex-direction: column;
		justify-content: center;
		display: flex;
		@include xl {
			display: none; } }
	&__info-title {
		margin: auto 0;
		color: rgba($black,.7);
		transform: scale(-1) translateY(-156px); }
	&__info-text {
		position: relative;
		margin-top: auto;
		color: rgba($black,.2);
		transform: scale(-1);
		&:before {
			position: absolute;
			content: '';
			left: 50%;
			top: 100%;
			margin-top: 15px;
			height: 50px;
			width: 1px;
			background-color: rgba($black, .1); } }
	&__info-title,
	&__info-text {
		writing-mode: vertical-lr;
		white-space: nowrap; }
	&__inner {
		align-items: center;
		display: flex;
		@include sm {
			flex-direction: column-reverse; } }
	&__content {
		position: relative;
		margin-bottom: 8px;
		z-index: 1;
		width: 53%;
		@include lg {
			width: 60%; }
		@include sm {
			width: 100%; } }
	&__title {
		margin-bottom: 16px; }
	&__text {
		max-width: 343px; }
	&__footer {
		margin-top: 27px; }
	&__btn {
		&:not(:last-child) {
			margin-right: 36px;
			@include sm {
				margin-right: 24px; } } }
	&__btn2 {
		&:not(:last-child) {
			padding: 10px 20px 9px 24px;
			margin-right: 36px;
			@include sm {
				margin-right: 24px; } } }
	& > .animateBtn {
		background-color: #1c87c9;
		-webkit-border-radius: 60px;
		border-radius: 60px;
		border: none;
		color: #eeeeee;
		cursor: pointer;
		display: inline-block;
		font-size: 20px;
		padding: 10px 10px;
		text-align: center;
		text-decoration: none; }
	&__img {
		position: relative;
		width: 47%;
		&:before {
			position: absolute;
			content: '';
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 1000px;
			height: 1000px;
			border-radius: 50%;
			z-index: -1;
			pointer-events: none;
			border: 1px solid rgba(0, 0, 0, 0.05);
			opacity: 0;
			@include tr(opacity);
			html.is-loaded & {
				opacity: 1;
				transition-delay: .6s; }
			@include md {
				display: none; } }
		@include lg {
			width: 40%; }
		@include sm {
			margin-bottom: 50px;
			width: 100%;
			justify-content: center;
			display: flex; }
		img,
		svg {
			margin: 29px -120px 0 12px;
			display: block;
			@include md {
				margin: 0;
				max-width: 400px;
				height: auto; }
			@include sm {
				max-width: 80%; } } } }

.hero-svg {
	position: relative;
	transition: transform .4s ease-out;
	&.is-animate {
		transform: translate(-20px,-20px);
		transition-delay: .7s; }

	&__logo-cube {
		stroke-dasharray: 500;
		stroke-dashoffset: 500;
		.hero-svg.is-animate & {
			animation: dashCube .5s linear forwards;
			animation-delay: .3s; } }
	&__logo-line {
		stroke-dasharray: 1800;
		stroke-dashoffset: 1800;
		.hero-svg.is-animate & {
			animation: dashLine .8s linear forwards; } }
	&__shadow {
		path,
		polygon {
			transform: translate(-20px,-20px);
			transition: transform .4s ease-out;
			.hero-svg.is-animate & {
				transform: translate(0,0);
				transition-delay: .7s; } } }
	&__clouds,
	&__cube,
	&__semicircles,
	&__lines {
		path,
		polygon {
			opacity: 0;
			transform: scale(.99);
			transition: opacity .3s ease-out, transform  .3s ease-in-out;
			.hero-svg.is-animate & {
				opacity: 1;
				transform: scale(1); } } }
	&__clouds {
		path {
			$animation-delay: 0.9s;
			@for $i from 1 through 3 {
				&:nth-child(#{$i}) {
					.hero-svg.is-animate & {
						transition-delay: $animation-delay;
						$animation-delay: $animation-delay + .1s; } } } } }

	&__cube {
		polygon {
			$animation-delay: 1.1s;
			@for $i from 1 through 3 {
				&:nth-child(#{$i}) {
					.hero-svg.is-animate & {
						transition-delay: $animation-delay;
						$animation-delay: $animation-delay + .12s; } } } } }
	&__semicircles {
		path {
			$animation-delay: 1.3s;
			@for $i from 1 through 3 {
				&:nth-child(#{$i}) {
					.hero-svg.is-animate & {
						transition-delay: $animation-delay;
						$animation-delay: $animation-delay + .1s; } } } } }

	&__lines {
		polygon {
			$animation-delay: 1.4s;
			@for $i from 1 through 6 {
				&:nth-child(#{$i}) {
					.hero-svg.is-animate & {
						transition-delay: $animation-delay;
						$animation-delay: $animation-delay + .11s; } } } } }

	&__petals-third path,
	&__petals-third polygon,
	&__petals-first path,
	&__petals-first polygon,
	&__petals-second path,
	&__petals-second polygon {
		opacity: 0;
		transition: opacity .3s ease-out, transform  .3s ease-in-out;
		transform-origin: center bottom;
		transform: scale(.99);
		.hero-svg.is-animate & {
			opacity: 1;
			transform: scale(1); } }
	&__petals-third path,
	&__petals-third polygon {
		.hero-svg.is-animate & {
			transition-delay: 1.7s; } }
	&__petals-first path,
	&__petals-first polygon {
		.hero-svg.is-animate & {
			transition-delay: 1.85s; } }
	&__petals-second path,
	&__petals-second polygon {
		.hero-svg.is-animate & {
			transition-delay: 2s; } }


	&__person-bottom-right,
	&__person-bottom-left,
	&__person-top {
		opacity: 0;
		transition: opacity .4s ease-in-out;
		.hero-svg.is-animate & {
			opacity: 1; } }
	&__person-top {
		.hero-svg.is-animate & {
			transition-delay: 1.9s; } }
	&__person-bottom-left {
		.hero-svg.is-animate & {
			transition-delay: 2.2s; } }
	&__person-bottom-right {
		.hero-svg.is-animate & {
			transition-delay: 2.5s; } } }

@keyframes dashLine {
	from {
		stroke-dashoffset: 1800; }
	to {
		stroke-dashoffset: 0; } }

@keyframes dashCube {
	from {
		stroke-dashoffset: 500; }
	to {
		stroke-dashoffset: 0; } }

@keyframes slideInFromLeft {
	0% {
		transform: translateX(-800%); }
	100% {
		transform: translateX(0); } }

@keyframes glowing {
	0% {
		background-color: white;
		color: #fff;
		box-shadow: 0 0 3px #dde3f9; }
	100% {
		background-color: #dde3f9;
		color: #dde3f9;
		box-shadow: 0 0 3px #dde3f9; } }

.arr_btn_animate {
	animation: 1s ease-out 1s 1 slideInFromLeft;
	display: inherit !important; }

.arr_btn_glow_animate {
	animation: 1s ease-out 1s 1 glowing; }

.arr_btn {
	width: 14px; }

