.workwithus {
	padding: 120px 0;
	@include sm {
		padding: 50px 0; }
	&__titleinfo {
		margin-bottom: 30px; }
	&__title {
		margin-bottom: 38px; }
	&__row {
		@include sm {
			flex-direction: column; } }
	&__col {
		&:not(:last-child) {
			@include sm {
				margin-bottom: 20px; } } }
	&__btn {
		&:not(:last-child) {
			margin-right: 40px;
			@include sm {
				margin-right: 23px; } } }
	&__img {
		margin: -143px 0;
		display: flex;
		justify-content: flex-end;
		@include sm {
			margin: 30px 0 0;
			display: none; }
		img,
		svg {
			display: block;
			margin-right: -71px;
			@include sm {
				margin: 0;
				max-width: 100%; } } } }

.workwithus-svg {
	position: relative;
	transition: transform .4s ease-out;
	&.is-animate {
		transform: translate(-8px,-8px);
		transition-delay: .7s; }

	&__logo-cube {
		stroke-dasharray: 800;
		stroke-dashoffset: 800;
		transition: stroke-dashoffset 1.5s ease-out;
		.workwithus-svg.is-animate & {
			stroke-dashoffset: 0;
			transition-delay: .5s; } }
	&__logo-line {
		stroke-dasharray: 1600;
		stroke-dashoffset: 1600;
		transition: stroke-dashoffset 3s ease-out;
		.workwithus-svg.is-animate & {
			stroke-dashoffset: 0; } }
	&__circle {
		stroke: transparent;
		transition: stroke .3s ease-out;
		.workwithus-svg.is-animate & {
			stroke: $black;
			transition-delay: 1s; } }
	&__shadow {
		transform: translate(-10px,-10px);
		transition: transform .4s ease-out;
		.workwithus-svg.is-animate & {
			transform: translate(0,0);
			transition-delay: .7s; } }
	&__anim-third rect,
	&__anim-first rect,
	&__anim-second path {
		opacity: 0;
		transition: opacity .3s ease-out;
		.workwithus-svg.is-animate & {
			opacity: 1; } }

	&__anim-first {
		rect {
			$animation-delay: 1s;
			@for $i from 1 through 3 {
				&:nth-child(#{$i}) {
					.workwithus-svg.is-animate & {
						transition-delay: $animation-delay;
						$animation-delay: $animation-delay + .1s; } } } } }
	&__anim-second {
		path {
			$animation-delay: 1.1s;
			@for $i from 1 through 3 {
				&:nth-child(#{$i}) {
					.workwithus-svg.is-animate & {
						transition-delay: $animation-delay;
						$animation-delay: $animation-delay + .1s; } } } } }
	&__anim-third {
		rect {
			$animation-delay: 1.3s;
			@for $i from 1 through 6 {
				&:nth-child(#{$i}) {
					.workwithus-svg.is-animate & {
						transition-delay: $animation-delay;
						$animation-delay: $animation-delay + .1s; } } } } } }


@keyframes dashLogoCube {
	from {
		stroke-dashoffset: 800; }
	to {
		stroke-dashoffset: 0; } }
