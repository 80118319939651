.consulting {
	padding: 96px 0 80px;
	@include md {
		padding: 50px 0; }
	&__col {
		&:not(:last-child) {
			margin-bottom: 40px; } }
	&__wrap {
		max-width: 460px;
		@include sm {
			max-width: 100%; } }
	&__titleinfo {
		margin-bottom: 35px; }
	&__title {
		margin-bottom: 30px;
		@include md {
			margin-bottom: 20px; } }
	&__footer {
		margin-top: 35px; }
	&__concept {
		counter-reset: list; }
	&__item {
		position: relative;
		padding-left: 70px;
		@include md {
			padding-left: 44px; }
		&:not(:last-child) {
			margin-bottom: 39px;
			@include md {
				margin-bottom: 20px; } }
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			counter-increment: list;
			content: counter(list);
			@include f(24px,900,1.375);
			color: $black;
			opacity: 0.3; }
		&:nth-of-type(-n+9) {
			&:before {
				content: '0'counter(list); } } }
	&__titlexs {
		margin-bottom: 24px;
		@include md {
			margin-bottom: 15px; } }
	&__list {
		li {
			position: relative;
			display: block;
			&:before {
				content: '- '; } } } }
