// colors
$white: #fff;
$black: #000;
$blue: #002DDC;

$text: rgba($black, .4);


$avenir: Avenir, Arial, sans-serif;

$tr: 0.3s;
