[data-accordion-item] {
	&[accordion-on-init] {
		[data-accordion-container] {
			display: block;
			@include md {
				display: none; } } } }
[data-accordion-trigger] {
	cursor: pointer;
	user-select: none; }
[data-accordion-container] {
	display: none; }
