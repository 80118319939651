.icon-arr {
  width: 1.57em;
  height: 1em;
  fill: currentColor;
}
.icon-github {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-linkedin {
  width: 0.9em;
  height: 1em;
  fill: currentColor;
}
.icon-twitter {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
